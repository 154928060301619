import React, { useState } from 'react';

import {
	Grid,
	Button,
	makeStyles,
	Collapse,
	IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { ExpandedSection } from './ExpandedSection';

export const SoftwareFeatures = ({
	softwareFeatures,
	accentColor,
}) => {
	const {
		features,
		expandText,
		hideText,
		expandedListHeader,
		expandedListSubheader,
		associatedProducts,
	} = softwareFeatures;

	const [expanded, setExpanded] = useState(false);

	return (
		<>
			<Collapse in={expanded}>
				<ExpandedSection
					header={expandedListHeader}
					subheader={expandedListSubheader}
					features={features.slice(22,49)}
					associatedProducts={associatedProducts}
				/>
			</Collapse>
			<Grid container alignItems='center' justifyContent='center'>
				<Button
					variant='contained'
					size='small'
					onClick={(e) => setExpanded(!expanded)}
					style={{
						background: accentColor ?? '#002D5C',
						color: 'white',
						margin: '2rem 0',
					}}>
					{expanded ? hideText : expandText}
					<IconButton
						aria-label='expand row'
						size='small'
						style={{ color: 'white' }}>
						{expanded ? <RemoveIcon /> : <AddIcon />}
					</IconButton>
				</Button>
			</Grid>
		</>
	);
};

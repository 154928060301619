import React, { useContext } from 'react';

import { fade, makeStyles } from '@material-ui/core/styles';
// import SearchIcon from '@material-ui/icons/Search';
import { Button, InputBase, Grid } from '@material-ui/core';

import FormModalContext from '../../context/FormModalContext';
import PardotContext from '../../context/PardotContext';

const useStyles = makeStyles((theme) => ({
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.borderLightGray, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.borderLightGray, 0.25),
		},
		// marginRight: theme.spacing(5),
		marginLeft: 0,
		marginBottom: 0,
		marginTop: '1rem',
	},

	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: '19px 16px 20px 16px',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		borderRadius: '4px 0 0 4px',
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
		transition: theme.transitions.create('width'),
		[theme.breakpoints.down('xs')]: { fontSize: '.8rem' },
		[theme.breakpoints.down('sm')]: {},
	},

	searchButton: {
		padding: '12.5px 32px',
		position: 'absolute',
		fontSize: '1.2rem',
		top: '0',
		right: '0',
		borderRadius: '0 4px 4px 0',
		boxShadow: 'none !important',
		// opacity: 1,
		transition: 'all 0.35s ease !important',
		[theme.breakpoints.down('lg')]: { padding: '12.5px 24px' },
		[theme.breakpoints.down('md')]: { padding: '12.5px 16px' },
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('xs')]: { padding: '10.5px .86rem' },
		// '&:hover': {
		// 	opacity: '0.85 !important',
		// },
	},
	label: {
		fontSize: '1.5rem',
		fontWeight: 500,
	},
}));

export const EmailInput = ({ accentColor, ctaText, fs }) => {
	const classes = useStyles();
  const { handleModalClick } = useContext(FormModalContext);
	const { email, setEmail } = useContext(PardotContext);

	const handleChange = (e) => {
		const { value } = e.target;

		setEmail(value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		handleModalClick();
	};
	return (
		<Grid
			container
			direction='row'
			justifyContent='flex-start'
			alignItems='center'
			className={classes.search}>
			<form
				onSubmit={(e) => handleSubmit(e)}
				style={{ background: 'white', width: '100%', borderRadius: '6px' }}>
				<InputBase
					placeholder='Enter your email address'
					style={{ width: '100%' }}
					classes={{
						input: classes.inputInput,
					}}
					value={email}
					onChange={(e) => handleChange(e)}
				/>
			</form>
			<Button
				className={classes.searchButton}
				variant='contained'
				size='large'
				color='primary'
				style={{
					marginLeft: '-4.2rem',
					background: accentColor ? accentColor : fs ? '#2A7ABC' : null,
				}}
				classes={{
					label: classes.label,
				}}
				onClick={(e) => handleSubmit(e)}>
				{ctaText}
			</Button>{' '}
		</Grid>
	);
};

import React, { useEffect, useState, useRef } from 'react';
import { useElementSize } from 'use-element-size';

export const WaveDownSVG = ({ fill, oneSide, heroRef, shadow }) => {
	const [height, setHeight] = useState(0);
	const ref = useRef(null);

	useEffect(() => {
		const browser = typeof window !== 'undefined';

		const onResize = () => {
			setHeight(ref.current?.clientHeight);
		};

		browser && window.addEventListener('resize', onResize);

		setHeight(ref.current?.clientHeight);
	});

	return (
		<svg
			style={{
				marginBottom: `-${height}px`,
				filter: shadow
					? 'drop-shadow(0px 15px 8px rgb(0 45 92 / 0.1))'
					: 'none',
			}}
			ref={ref}
			role="presentation"
			viewBox='0 0 2160 263'
			fill={fill}
			preserveAspectRatio='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				id='Wave'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2160 0H0V262.5C360 182.5 720 142.5 1080 142.5C1440 142.5 1800 182.5 2160 262.5V0Z'
				fill={fill}
			/>
		</svg>
	);
};

import React, { useContext } from 'react';
import PortableText from 'react-portable-text';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	Container,
	useMediaQuery,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import { EmailInput } from '../Home/EmailInput';
import { WaveUpHero } from '../WaveSVGs/WaveUpHero';
import { HeroBody } from '../CustomStyles/Typography';
import FaqSearchContext from '../../context/FaqSearchContext';
import { HeroCtaButton } from './HeroCtaButton';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.white,
		marginBottom: '1rem',
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			margin: '0px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	smsText: {
		color: theme.white,
		marginBottom: '1rem',
		textAlign: 'left',
		[theme.breakpoints.down('md')]: {
			margin: '0px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	header: {
		lineHeight: 1.1,
		marginTop: '50px',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.4rem',
			marginBottom: '1rem',
			lineHeight: 1.2,
			textAlign: 'left',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
			// marginTop: '20px',
		},
	},
	personaHeader: {
		lineHeight: 1.1,
		marginTop: '50px',
		marginBottom: '10%',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.4rem',
			lineHeight: 1.2,
			marginBottom: '22%',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.4rem',
			lineHeight: 1.2,
			marginBottom: '14%',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
			// marginTop: '20px',
		},
	},
	subHeader: {
		marginTop: '50px',
		// marginBottom: '-1rem',
		fontWeight: 400,
		fontSize: '1.5rem',
		lineHeight: 1.3,
		textTransform: 'uppercase',
		fontFamily: ['Roboto', 'sans-serif'].join(),
		[theme.breakpoints.down('md')]: {
			fontSize: '1.3rem',
		},
	},
	imgCont: {
		padding: '6rem 0 4rem 0',
		maxWidth: '100vw',
		height: '100%',
		overflow: 'visible',
		backgroundSize: 'cover',
		background:
			'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
		[theme.breakpoints.down('sm')]: { padding: '4rem 0' },

		[theme.breakpoints.down('xs')]: {
			padding: '3rem 0',
			backgroundPosition: 'right',
		},
	},
	imgRight: {
		marginLeft: '-10rem',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '-9rem',
			height: '560px',
		},
		// [theme.breakpoints.down('md')]: {
		// 	marginLeft: '-4rem',
		// 	height: '380px',
		// },
		[theme.breakpoints.down('sm')]: {
			marginLeft: '-8rem',
			height: '300px',
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0',
			height: '260px',
		},
	},
	star: {
		marginRight: '.5rem',
		color: '#FFCA43',
	},
	wave: {
		[theme.breakpoints.down('lg')]: {},
		// [theme.breakpoints.down('md')]: {
		// 	marginTop: '-7rem',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	marginTop: '-7rem',
		// },
		[theme.breakpoints.down('xs')]: {},
	},
	inputLabel: {
		'&.shrink': {
			backgroundColor: 'white',
			padding: '2px',
			borderRadius: '5px',
		},
	},
	cta: {},
	searchIconBackground: {
		height: '56px',
		width: '56px',
		position: 'relative',
		left: '22px',
		background: '#002D5C',
	},
	searchIcon: {
		color: '#fff',
		height: '56px',
		width: '56px',
		padding: '6px',
		background: '#002D5C',
		borderRadius: '0 4px 4px 0',
	},
}));

export const Hero = ({
	hero,
	accentColor,
	leadership,
	login,
	feature,
	faq,
	sms,
	persona,
	fs,
	faqRef,
	pricing,
}) => {
	const classes = useStyles();
	const xs = useMediaQuery('(max-width: 481px)');

	//if hero has subheader then remove margin-top from h1
	const isFeature =
		feature && hero._rawContent[0].style === 'h4' ? true : false;

	const { handleChange, defaultFaq } = useContext(FaqSearchContext);

	return (
		<>
			<BgImage
				className={classes.imgCont}
				image={hero?.backgroundImage?.asset?.gatsbyImageData}
				style={{
					// backgroundImage: `url(${hero?.backgroundImage?.asset?.gatsbyImageData.images.fallback.src})`,
					lineHeight: '0',
					overflow: 'hidden',
				}}>
				<Container fixed>
					<Grid container direction='row' justifyContent='center'>
						<Grid
							container
							item
							direction='column'
							justifyContent='center'
							alignItems='center'
							xs={12}
							md={10}>
							{hero?._rawContent.map((content, index) => (
								<PortableText
									content={content}
									key={index}
									className={classes.text}
									serializers={{
										h1: ({ children }) => (
											<Typography
												variant='h1'
												className={
													persona ? classes.personaHeader : classes.header
												}
												style={{
													marginTop: isFeature ? 0 : xs ? '20px' : '50px',
													textAlign: pricing && 'center',
												}}>
												{children}
											</Typography>
										),
										h2: ({ children }) => (
											<Typography
												variant='h2'
												className={classes.header}
												style={{ fontSize: login && '1.5rem' }}>
												{children}
											</Typography>
										),
										h4: ({ children }) => (
											<Typography
												variant='h4'
												className={
													isFeature ? classes.subHeader : classes.header
												}>
												{children}
											</Typography>
										),
										normal: ({ children }) => (
											<HeroBody leadership={leadership} pricing={pricing}>
												{children}
											</HeroBody>
										),
									}}
								/>
							))}{' '}
						</Grid>
						{!!hero.ctaText || faq?.length || hero.ctaButtonText ? (
							<Grid item xs={12} md={6}>
								{faq?.length ? (
									<Grid container direction='row' style={{ padding: '2rem 0' }}>
										<TextField
											variant='outlined'
											onKeyUp={(e) => handleChange(e, defaultFaq, faq, faqRef)}
											fullWidth
											label='SEARCH FOR YOUR TOPIC'
											style={{
												borderRadius: '4px',
												background: '#FFF',
												boxShadow:
													'8px 8px 10px rgb(50 50 93 / 15%), 0 2px 5px rgb(0 0 0 / 12%)',
											}}
											InputLabelProps={{
												classes: {
													root: classes.inputLabel,
													shrink: 'shrink',
												},
											}}
											InputProps={{
												type: 'search',
												endAdornment: (
													<InputAdornment
														className={classes.searchIconBackground}>
														<SearchTwoToneIcon className={classes.searchIcon} />
													</InputAdornment>
												),
											}}
										/>
									</Grid>
								) : hero.ctaText ? (
									<EmailInput
										accentColor={accentColor}
										ctaText={hero.ctaText}
										fs={fs}
									/>
								) : hero.ctaButtonText ? (
									<HeroCtaButton hero={hero} />
								) : null}
								{/* <EmailInput accentColor={accentColor} ctaText={hero.ctaText} /> */}
							</Grid>
						) : null}
					</Grid>
					{}
				</Container>
				<WaveUpHero height='213' width='100%' fill='white' />
			</BgImage>
		</>
	);
};
